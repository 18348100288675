import React from "react"
import { Link } from "react-scroll"

import Layout from "../components/layout"
import SEO from "../components/seo"
import AniLink from "../components/AniLink"
import { services } from "../assets/js/helpers"

import shape1 from "../assets/images/vector/services/shape-1.svg"
import shape2 from "../assets/images/vector/services/shape-2.svg"
import shape3 from "../assets/images/vector/services/shape-3.svg"
import shape4 from "../assets/images/vector/services/shape-4.svg"

const ServicesPage = props => {
  return (
    <Layout>
      <SEO
        title="Services"
        description="With fluency in major development languages, our teams excel in every solution powering native, cross-platform custom software and services."
      />
      <section className="services-banner section-top-200 section-bottom-50">
        <span className="services-shape-1">
          <img src={shape1} />
        </span>
        <span className="services-shape-2">
          <img src={shape2} />
        </span>
        <span className="services-shape-3">
          <img src={shape3} />
        </span>
        <span className="services-shape-4">
          <img src={shape4} />
        </span>
        <div className="container">
          <div className="d-flex flex-column text-center">
            <div className="small-caption color-cerulean-blue mb-0">
              Services
            </div>
            <div className="title color-raven-black">
              Technology Custom Services
            </div>
            <div className="section-50">
              <div className="landing-service">
                {services.map((value, key) => {
                  if (props.location.pathname === "/services") {
                    return (
                      <Link
                        key={key}
                        to={value.id}
                        className="landing-service-item cursor-pointer"
                        smooth={true}
                      >
                        <img src={value.image} />
                        <div className="landing-service-item-description">
                          {value.title}
                        </div>
                      </Link>
                    )
                  } else {
                    return (
                      <AniLink
                        key={key}
                        className="landing-service-item cursor-pointer"
                        to={value.link}
                      >
                        <img src={value.image} />
                        <div className="landing-service-item-description">
                          {value.title}
                        </div>
                      </AniLink>
                    )
                  }
                })}
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="section-bottom-150">
        <div className="container">
          {services.map((value, key) => {
            return (
              <div
                key={key}
                id={value.id}
                className="service-container section-top-300"
              >
                <div className="service-header">
                  <img className="service-logo" src={value.image} />
                  <div className="service-lead">
                    <div className="subtitle mb-0 color-cerulean-blue">
                      {value.title}
                    </div>
                    <div className="heading color-raven-black">
                      {value.subtitle}
                    </div>
                  </div>
                </div>
                <div className="service-body">
                  <div className="subtitle">{value.description}</div>
                </div>
              </div>
            )
          })}
        </div>
      </section>
      <section id="contact">
        <div className="contact">
          <div className="p-relative section-100 container text-center text-md-left">
            <div className="heading color-pine-green mb-2">
              Let's have a discussion!
            </div>
            <div className="mb-5 color-spring-green">
              Discuss all of your digital needs and start build your business.
            </div>
            <a
              href="https://api.whatsapp.com/send?phone=6285262798297&text=Hi%2C+Springkraf%21%0AMy+name+is%3A+%0AI+want+to+talk+about%3A&source&data&lang=en"
              target="_blank"
            >
              <div className="d-inline button button-primary">Chat with us</div>
            </a>
          </div>
        </div>
      </section>
    </Layout>
  )
}

export default ServicesPage
